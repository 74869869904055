import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Logo = styled.a`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 600;
  gap: 8px;
  justify-content: center;
  margin: 32px auto 0;
  max-width: 320px;
  width: 100%;

  > div {
    padding-top: 0;
  }

  > span {
    color: ${colors.activeDisabled};
  }

  img {
    width: 100%;
  }
`;

export const HelpButton = styled(Button)`
  &.MuiButtonBase-root {
    align-items: center;
    color: ${colors.textSecondary};
    font-weight: 400;
    display: flex;
    gap: 8px;
    justify-content: center;
    margin: -16px 0 auto;
    max-width: 160px;

    &.MuiButton-sizeLarge {
      padding: 16px 0;
    }

    &:hover {
      background-color: inherit;
    }

    .icon-container {
      background-color: ${colors.borderDark};
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin-left: -4px;
      position: relative;

      svg {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        fill: ${colors.text};
        font-size: 1.8rem;
      }
    }

    @media only screen and (min-width: 541px) {
      margin: -16px 0 auto 32px;
    }
  }
`;

export const HelpContainer = styled.div`
  padding: 0 0 18px;
  width: 100%;

  iframe {
    border: none;
    height: calc(100dvh - 160px);
    width: 100%;
  }

  button {
    background-color: ${colors.background};
    color: ${colors.text};
    display: block;
    margin: 0 auto;
    width: calc(100% - 36px);
  }
`;

export const AppButtonsContainer = styled(StyledContainer)`
  border-radius: 8px;
  border: 1px solid #434343;
  color: ${colors.textSecondary};
  margin: 20px auto 70px;
  max-width: calc(100% - 36px);
  padding: 24px 22px;

  strong {
    font-size: 1.4rem;
  }

  @media only screen and (min-width: 541px) {
    max-width: calc(100% - 112px);
  }
`;

export const AppButtonsTitle = styled.h5`
  color: ${colors.text};
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 8px;
`;

export const AppButtonsCopy = styled.p`
  font-size: 1.6rem;
  margin: 0 0 24px;
`;

export const AppButtons = styled(StyledContainer)`
  gap: 8px;
  margin: 8px auto;

  > a {
    display: flex;

    img {
      display: flex;
      max-width: 156px;
      width: 100%;
    }
  }
`;

export const IconContainer = styled.div`
  background-color: ${colors.lighterBackground};
  border-radius: 12px;
  padding: 15px;
  margin: -52px auto 8px;

  img {
    height: 42px;
    width: 42px;
  }
`;

export const FooterButtons = styled(StyledContainer)`
  gap: 8px;
  margin: 16px auto;
  max-width: 320px;
  width: 100%;
  padding: 18px;

  button {
    border-color: ${colors.utilityGray};
    color: ${colors.utilityGray};
  }
`;
