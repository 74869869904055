import * as Styled from "./VendorEmptyTab.styles";

import { useCallback, useState } from "react";

import Button from "@component/Button/Button";
import CheckIcon from "@mui/icons-material/Check";
import { Divider } from "@mui/material";
import RooamCredit from "@component/RooamCredit/RooamCredit";
import TabActionSheet from "@component/TabActionSheet/TabActionSheet";
import { useNavigate } from "react-router-dom";
import useRooamCredit from "@hooks/useRooamCredit";
import { useTab } from "@context/TabContext";

interface EmptyTabProps {
  placeCode?: string;
  tabNumber?: string;
}

function VendorEmptyTab({ tabNumber, placeCode }: EmptyTabProps) {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { closeOpenTab } = useTab();

  const { hasRooamCredit } = useRooamCredit();

  const navigate = useNavigate();

  const handleActionSheetIsOpen = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);

  const handleCloseEmptyTab = useCallback(async () => {
    setIsLoading(true);

    const closeAndReturnNextScreen = await closeOpenTab({ tip: "20" });

    handleActionSheetIsOpen();

    if (closeAndReturnNextScreen) {
      navigate(`/venue/${placeCode}/voided`, {
        replace: true,
      });
    }

    setIsLoading(false);
  }, [closeOpenTab, handleActionSheetIsOpen, navigate, placeCode]);

  return (
    <Styled.Wrapper direction="column">
      <Styled.Container direction="column">
        <Styled.CheckIconContainer size={50}>
          <CheckIcon />
        </Styled.CheckIconContainer>
        <Styled.Title>Your Tab is Open!</Styled.Title>
        <p>
          Order with your bartender or server and show the tab number below:
        </p>
        <Styled.TabNumber>{tabNumber}</Styled.TabNumber>
      </Styled.Container>
      {hasRooamCredit && <RooamCredit />}
      <Divider />
      <Button
        text="Close Tab"
        variant="outlined"
        onClick={handleActionSheetIsOpen}
      />
      <TabActionSheet
        isActionSheetOpen={isActionSheetOpen}
        onActionSheetOpen={handleActionSheetIsOpen}
        onPerformAction={() => {
          void handleCloseEmptyTab();
        }}
        title="Are you sure?"
        message="No items have been added to your tab. Please confirm you would like to close it."
        actionText="Confirm"
        isActionLoading={isLoading}
      />
    </Styled.Wrapper>
  );
}

export default VendorEmptyTab;
