import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  padding: 0 16px 16px;
  gap: 8px;

  button {
    border: 1px solid ${colors.background};
    font-size: 1.7rem;

    &:hover,
    &:nth-last-child(2) {
      background-color: ${colors.background};
      color: ${colors.text};
    }

    &:last-child {
      border-color: ${colors.secondary};
      color: ${colors.secondary};

      &:hover {
        background-color: transparent;
      }
    }
  }
`;
