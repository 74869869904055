import * as Styled from "./VendorFooter.styles";

import { useCallback, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import Button from "@component/Button/Button";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import VendorButtons from "./VendorButtons";
import VendorLogo from "@vendor/components/VendorLogo/VendorLogo";
import { useLocation } from "react-router-dom";
import { useTab } from "@context/TabContext";

export default function VendorFooter(): React.ReactElement {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const year = new Date().getFullYear();

  const { feed } = useTab();

  const location = useLocation();
  const { pathname } = location;

  const isEmptyTab =
    pathname.includes("/tab") && (feed?.tab?.items?.length ?? 0) <= 0;

  const pathRegex = /(phone|verify|tab|voided)/;

  const handleGiveFeedback = useCallback(() => {
    window.open("mailto:help@rooam.co", "blank");
  }, []);

  const handleAboutRooam = useCallback(() => {
    window.open("https://rooam.co", "blank");
  }, []);

  const handleHelp = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);

  return (
    <>
      {pathRegex.test(pathname) && !isEmptyTab && (
        <>
          <Styled.HelpButton
            node={
              <>
                <span className="icon-container">
                  <QuestionMarkIcon color="disabled" fontSize="large" />{" "}
                </span>
                <span>Need help?</span>
              </>
            }
            onClick={handleHelp}
            variant="text"
          />
          <BottomSheet
            id="bottom-sheet"
            className="moreMenu-sheet"
            open={isActionSheetOpen}
            onDismiss={handleHelp}
            style={{
              backgroundColor: "#ffffff",
            }}
          >
            <Styled.HelpContainer>
              <iframe
                title="Roam WebTab Help"
                src="https://docs.google.com/forms/d/e/1FAIpQLSfj-MFkVgr8JgYH-aEBvXb2Ak0ZLx1BGp0nSVGKrao_gozUng/viewform?embedded=true"
              />
              <Button text="Go Back" onClick={handleHelp} />
            </Styled.HelpContainer>
          </BottomSheet>
        </>
      )}
      <VendorButtons />
      <Styled.Logo href="https://rooam.co/" target="_blank" rel="noreferrer">
        <VendorLogo />
        <span>Powered by Rooam</span>
      </Styled.Logo>
      <Styled.FooterButtons>
        <Button
          onClick={handleGiveFeedback}
          text="Give feedback"
          size="medium"
          variant="outlined"
        />
        <Button
          onClick={handleAboutRooam}
          text="About Rooam"
          size="medium"
          variant="outlined"
        />
      </Styled.FooterButtons>
      <span className="text-lighter">© Copyright {year} - Rooam, Inc.</span>
    </>
  );
}
