import * as Styled from "./VendorTopBar.styles";

import React, { useCallback, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import Button from "@component/Button/Button";
import LocationImgPlaceholder from "@vendor/assets/images/location-image.jpg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VendorBackButton from "../VendorBackButton/VendorBackButton";
import VendorMoreMenu from "@vendor/components/VendorMoreMenu/VendorMoreMenu";
import { useAuth } from "@context/AuthContext";
import { useLocation } from "react-router-dom";
import { usePlace } from "@context/PlaceContext";

const VendorTopBar = () => {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const { isAuthenticated } = useAuth();

  const { place } = usePlace();

  const { pathname } = useLocation();

  const handleMoreMenu = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);

  const fullHeroSize = !!pathname.includes("/phone");

  return (
    <>
      <Styled.Wrapper direction="column">
        <Styled.LocationHero fullHeroSize={fullHeroSize}>
          {!fullHeroSize && (
            <Styled.LocationHeroContainer isAuthenticated={isAuthenticated}>
              <VendorBackButton />
              <h1>{place?.name}</h1>
              {isAuthenticated && (
                <Button
                  className="more-menu"
                  icon={<MoreVertIcon fontSize="large" color="inherit" />}
                  onClick={handleMoreMenu}
                  size="small"
                  variant="text"
                />
              )}

              <BottomSheet
                id="bottom-sheet"
                className="moreMenu-sheet"
                open={isActionSheetOpen}
                onDismiss={handleMoreMenu}
                style={{
                  backgroundColor: "#ffffff",
                }}
              >
                <VendorMoreMenu onCancel={handleMoreMenu} />
              </BottomSheet>
            </Styled.LocationHeroContainer>
          )}
          <img src={place?.image ?? LocationImgPlaceholder} alt="Location" />
        </Styled.LocationHero>
        {fullHeroSize && (
          <Styled.LocationInfo direction="column">
            <Styled.LocationProfileImg>
              {place?.logo && (
                <img src={place.logo} alt={`${place.name} logo`} />
              )}
            </Styled.LocationProfileImg>

            {place?.name && (
              <Styled.LocationName>
                <h1>{place?.name}</h1>
                <span>{place.address}</span>
              </Styled.LocationName>
            )}
          </Styled.LocationInfo>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default VendorTopBar;
