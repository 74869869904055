import * as Styled from "./VendorFooter.styles";

import AppleStoreImg from "@vendor/assets/images/appstore-badge.png";
import PlayStoreImg from "@vendor/assets/images/googleplay-badge.png";
import VendorIcon from "../VendorLogo/VendorIcon";
import { useLocation } from "react-router-dom";

function VendorButtons() {
  const location = useLocation();
  const { pathname } = location;

  if (!pathname.includes("/complete")) {
    return <></>;
  }

  return (
    <Styled.AppButtonsContainer direction="column">
      <Styled.IconContainer>
        <VendorIcon />
      </Styled.IconContainer>
      <Styled.AppButtonsTitle>Download the Rooam App</Styled.AppButtonsTitle>
      <Styled.AppButtonsCopy>
        Want to checkout even faster next time? Download the Rooam App and
        create an account to link up your payment information.
      </Styled.AppButtonsCopy>
      <Styled.AppButtons>
        <a
          href="https://apps.apple.com/us/app/rooam-pay-from-your-phone/id1141914731?itsct=apps_box_badge&amp;itscg=30200"
          target="_blank"
          rel="noreferrer"
          title="Download Rooam Pay for iPhone"
        >
          <img
            src={AppleStoreImg}
            title="appleStore-img"
            alt="AppStore Badge"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=co.rooam.rooamapp&utm_source=web_tab&utm_campaign=install_promote&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
          rel="noreferrer"
          title="Download Rooam Pay for Android"
        >
          <img
            src={PlayStoreImg}
            title="playStore-img"
            alt="Google Play Store badge"
          />
        </a>
      </Styled.AppButtons>
    </Styled.AppButtonsContainer>
  );
}

export default VendorButtons;
