import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Wrapper = styled(StyledContainer)`
  hr {
    border-color: ${colors.borderDark};
    margin: 22px 0 22px -58px;
    width: calc(100% + 116px);
  }

  .rooam-credit {
    margin-bottom: 0px;
  }
`;

export const Container = styled(StyledContainer)`
  align-items: center;
  margin: auto auto;
  max-width: 280px;
  text-align: center;
  padding: 120px 0 90px;

  p {
    line-height: 22px;
    margin: 0;
  }
`;

export interface CheckIconColorProps {
  outerBackgroundColor?: string;
  innerBackgroundColor?: string;
  size: number;
}

export const CheckIconContainer = styled(StyledContainer)<CheckIconColorProps>`
  align-items: center;
  background-color: ${(props) =>
    props.outerBackgroundColor ?? colors.successLight};
  border-radius: 50%;
  height: ${({ size }) => `${size}px`};
  justify-content: center;
  position: relative;
  width: ${({ size }) => `${size}px`};

  &:before {
    background-color: ${(props) =>
      props.innerBackgroundColor ?? colors.success};
    border-radius: 50%;
    content: "";
    height: ${({ size }) => `calc(${size}px - 25%)`};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${({ size }) => `calc(${size}px - 25%)`};
    z-index: 0;
  }

  svg {
    fill: #ffffff;
    height: ${({ size }) => `calc(${size}px - 50%)`};
    position: relative;
    width: ${({ size }) => `calc(${size}px - 50%)`};
    z-index: 1;
  }
`;

export const Title = styled.h2`
  color: ${colors.success};
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1;
  margin: 16px 0 12px;
`;

export const TabNumber = styled.span`
  font-size: 6rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4px;
  margin: 16px 0;
`;
