import BouncingChevronDown from "@component/Icons/BouncingChevronDown";
import TabActionSheet from "@component/TabActionSheet/TabActionSheet";

interface VendorFeedActionSheetProps {
  isActionSheetOpen: boolean;
  onActionSheetIsOpen: () => void;
  onCloseTab: () => void;
  tabNumber?: string;
  isLoading: boolean;
}

function VendorFeedActionSheet({
  isActionSheetOpen,
  onActionSheetIsOpen,
  onCloseTab,
  isLoading,
}: VendorFeedActionSheetProps) {
  return (
    <TabActionSheet
      isActionSheetOpen={isActionSheetOpen}
      onActionSheetOpen={onActionSheetIsOpen}
      onPerformAction={() => {
        void onCloseTab();
      }}
      title="Are you sure?"
      message="Make sure to check your items and your tip before closing your tab. When you are ready, tap 'Confirm' to close you tab."
      actionText="Confirm"
      isActionLoading={isLoading}
      customIcon={<BouncingChevronDown />}
    />
  );
}

export default VendorFeedActionSheet;
