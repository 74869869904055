import { useLocation, useNavigate } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";
import Button from "@component/Button/Button";
import { useCallback } from "react";

function VendorBackButton() {
  const navigate = useNavigate();

  const location = useLocation();

  const pathsToCheck = ["verify", "addpayment", "select"];
  const isValidPath = pathsToCheck.some((path) =>
    location.pathname.includes(path)
  );

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!isValidPath) {
    return <></>;
  }

  return (
    <Button
      className="back-btn"
      icon={<ArrowBack fontSize="large" color="inherit" />}
      onClick={handleGoBack}
      size="small"
      variant="text"
    />
  );
}

export default VendorBackButton;
