import * as Styled from "./VendorMoreMenu.styles";

import { useCallback, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import Button from "@component/Button/Button";
import { HelpContainer } from "@vendor/components/VendorFooter/VendorFooter.styles";
import useLogOut from "@utils/useLogOut";

interface TabMoreMenuProps {
  onCancel: () => void;
}

function TabMoreMenu({ onCancel }: TabMoreMenuProps) {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const { isLoginOut, handleLogOut } = useLogOut();

  const handleTriggerLogOut = useCallback(async () => {
    await handleLogOut();
    onCancel();
  }, [handleLogOut, onCancel]);

  const handleHelp = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);

    if (isActionSheetOpen) {
      onCancel();
    }
  }, [isActionSheetOpen, onCancel]);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <>
      <Styled.Container direction="column">
        <Button text="Need Help?" onClick={handleHelp} />
        <Button
          text="Log Out"
          isLoading={isLoginOut}
          onClick={() => {
            void handleTriggerLogOut();
          }}
        />
        <Button text="Cancel" onClick={handleCancel} size="medium" />
      </Styled.Container>
      <BottomSheet
        id="bottom-sheet-2"
        className="moreMenu-sheet"
        open={isActionSheetOpen}
        onDismiss={handleHelp}
        style={{
          backgroundColor: "#ffffff",
        }}
      >
        <HelpContainer>
          <iframe
            title="Roam WebTab Help"
            src="https://docs.google.com/forms/d/e/1FAIpQLSfj-MFkVgr8JgYH-aEBvXb2Ak0ZLx1BGp0nSVGKrao_gozUng/viewform?embedded=true"
          />
          <Button text="Go Back" onClick={handleHelp} />
        </HelpContainer>
      </BottomSheet>
    </>
  );
}

export default TabMoreMenu;
