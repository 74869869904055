import { IconContainer } from "./VendorIcon.styles";
import { IconFillColorProps } from "@component/Icons/Icon";
import RooamIcon from "../../assets/icon.svg";

export default function VendorIcon(
  props: IconFillColorProps
): React.ReactElement {
  return (
    <IconContainer>
      <img src={RooamIcon} alt="Rooam Logo" />
    </IconContainer>
  );
}
