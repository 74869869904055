import StyledContainer from "@GlobalComponents/StyledContainer";
import { Toolbar } from "@mui/material";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Wrapper = styled(StyledContainer)`
  color: ${colors.textSecondary};
  display: flex;
  -webkit-box-shadow: 0 3px 14px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0 3px 14px 0px rgba(0, 0, 0, 0.06);
  margin-top: -62px;
  width: 100%;

  .back-btn {
    color: ${colors.textDisabled};
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
    width: 51px;
  }

  .login-btn {
    min-width: 51px;
    width: auto;
  }

  .account-icon,
  .account-btn {
    width: 30px;
    height: 30px;
    background-color: transparent;
  }
`;

export const Container = styled(Toolbar)`
  &.overwrite {
    background-color: ${colors.lighterBackground};
    justify-content: space-between;
    padding: 7.7px 16px;

    @media (min-width: 600px) {
      min-height: initial;
    }
  }
`;

export const LocationName = styled.div`
  padding: 8px 0;
  font-size: 1.8rem;
  line-height: 2.2rem;

  h1 {
    color: ${colors.text};
    font-size: 2.8rem;
    line-height: 3.8rem;
    margin: 0;
  }
`;

interface LocationHeroProps {
  fullHeroSize?: boolean;
}

export const LocationHero = styled.div<LocationHeroProps>`
  display: flex;
  height: ${({ fullHeroSize }) => (fullHeroSize ? "140px" : "60px")};
  overflow: hidden;
  position: relative;
  width: 100%;

  h1 {
    text-shadow: 1px 1px 10px #000000;
    font-size: 1.6rem;
    flex: 100% 1 1;
    text-align: center;
  }

  img {
    opacity: ${({ fullHeroSize }) => (fullHeroSize ? "1" : "0.5")};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 0;
  }

  button {
    &.MuiButtonBase-root {
      align-items: center;
      display: flex;
      background-color: #000000;
      border-radius: 50%;
      height: 36px;
      justify-content: center;
      opacity: 1;
      overflow: hidden;
      padding: 0;
      width: 36px;
      z-index: 1;
      transition: none;
      transition-delay: 3s;

      &:hover {
        background-color: ${colors.lighterBackground};
        opacity: 1;
      }

      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
`;

interface LocationHeroContainerProps {
  isAuthenticated: boolean;
}

export const LocationHeroContainer = styled(
  StyledContainer
)<LocationHeroContainerProps>`
  gap: 8px;
  padding: 0 8px;
  position: relative;
  z-index: 1;
  width: 100%;

  h1 {
    color: ${colors.text};
  }

  ${({ isAuthenticated }) =>
    !isAuthenticated &&
    `
    padding-right: 45px
  `}
`;

export const LocationInfo = styled(StyledContainer)`
  background-color: transparent;
  border-bottom: 1px solid ${colors.border};
  margin-top: -48px;
  padding: 0 18px 24px;
  z-index: 1;

  @media only screen and (min-width: 541px) {
    padding: 0 56px 16px;
  }
`;

export const LocationProfileImg = styled.div`
  background-color: ${colors.background};
  border-radius: 50%;
  display: flex;
  height: 94px;
  width: 94px;
  overflow: hidden;
  padding: 8px;

  img {
    width: 100%;
    border-radius: 50%;
  }
`;
